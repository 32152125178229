"use client"
import React, { FC, useEffect, useState, useRef } from "react";
import { useSearchParams, useRouter } from 'next/navigation';
import { useLandingContext } from "../../../context/LandingProvider";
import classes from "./SearchForm.module.css";
import Horizontal from "./templates/Horizontal";
import Vertical from "./templates/Vertical";
import useResize from "../../../hooks/useResize";

//import { roomsHelper } from "../../_helpers/rooms";

interface IProps {
  type?: "vertical" | "horizontal";
  test?: boolean;
  className?: string;
}

const SearchForm: FC<IProps> = ({ type = "horizontal", test = false, className }) => {
  const router = useRouter();
  // const searchParams = useSearchParams();
  const { theme } = useLandingContext();
  const { width } = useResize();

  const [viewType, setViewType] = useState<string>(type);
  const [firstHeaderPosition, setFirstHeaderPosition] = useState(null);

  const handleScroll = () => {
    if (type == "vertical") return null;
    const width = window.innerWidth;
    const header = document.getElementsByClassName("book-form")[0];
    if (header) {
      if (width >= 1024) {
        const elementRect = header.getBoundingClientRect();
        //const sticky = elementRect.top;
        //@ts-ignore
        const sticky = header.offsetTop;
        // console.log("sticky", sticky);
        // console.log("pageYOffset", window.pageYOffset);
        if (window.pageYOffset > sticky) {
          header.classList.add(classes.sticky);
        } else {
          header.classList.remove(classes.sticky);
        }

        //header.style.transform = `translateY('0px' : '0'})`;
      } else {
        header.classList.remove(classes.sticky);
        //header.style.transform = 'translateY(0)';
      }
    }
  };

  // useEffect(() => {
  //   if (landing.width < 1024) {
  //     setViewType("vertical");
  //     handleScroll();
  //   } else {
  //     if (type != "vertical") {
  //       setViewType("horizontal");
  //     }

  //     handleScroll();
  //   }
  // }, [landing.width]);

  useEffect(() => {
    if (width < 1024) {
      setViewType("vertical");
      //handleScroll();
    } else {
      if (type != "vertical") {
        setViewType("horizontal");
      }

      //handleScroll();
    }
    if (width < 1024) return () => { }
    window.addEventListener("scroll", handleScroll);

    const header = document.getElementsByClassName("book-form")[0];
    if (header) {
      //@ts-ignore
      setFirstHeaderPosition(header.offsetTop)
      //const sticky = elementRect.top;
    }
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  ;
  const submitHandler = async (event) => {
    event.preventDefault();
    const checkIn = event.target.checkIn.value;
    const checkOut = event.target.checkOut.value;
    const nights = event.target.nights.value;
    const adults = event.target.adults.value;
    const kids = event.target.kids.value || null;
    let pathname = test ? "/searchtest" : "/search";
    const searchParams = new URLSearchParams();

    // Добавление параметров
    if (checkIn) searchParams.set('checkIn', checkIn);
    if (checkOut) searchParams.set('checkOut', checkOut);
    if (nights) searchParams.set('nights', nights.toString());
    if (adults) searchParams.set('adults', adults.toString());

    // Обработка kids
    if (kids) {
      const kidsArray = kids.split(','); // Разделить строку на массив
      kidsArray.forEach((kid) => {
        searchParams.append('kids', kid.trim()); // Добавить каждый возраст как отдельный параметр
      });
    }

    // Создание строки запроса
    const queryString = searchParams.toString();

    console.log("queryString", queryString);
    router.push(`${pathname}?${queryString}`);
  };


  return (
    <form suppressHydrationWarning onSubmit={submitHandler}>
      <section
        className={`${classes.formBox} py-2 px-3 ${className && className.length > 0 ? className : 'my-3'} w-100 book-form`}
        style={{ backgroundColor: theme.mainColor }}
      >
        {viewType == "horizontal" && <Horizontal />}
        {viewType == "vertical" && <Vertical />}
      </section>
    </form>
  );
};

export default SearchForm;
